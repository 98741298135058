<template>
  <!-- Prélèvement -->
  <form @submit.prevent="onSubmit()">
    <div class="organ-subview">
      <div class="tabs-material-subtitle no-padding-bottom">
        <Container>
          <h2>Organe</h2>
        </Container>
      </div>

      <Section>
        <Container>
          <SelectExtended
            id="organe_id"
            label="Nom de l'organe"
            :apiParams="{ sort: 'valeur.ASC' }"
            optionKey="id"
            optionValue="valeur"
            :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
            'organe'
          )}/valeur`"
          />
        </Container>
      </Section>
    </div>

    <div class="view-footer">
      <Btn text="Annuler" :to="{ name: 'essaiCalendar', params: {id: $route.params.id} }" />
      <Btn btnType="submit" text="Enregistrer" color="primary" />
    </div>
  </form>
</template>

<script>

import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'OrganSubview',

  components: {
    Container,
    Section,
    SelectExtended,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      organe_id: this.yup.object().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(validationSchema)
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      fiche: {},

      isSubmitting,
      errors,
      onSubmit,
      validationSchema,
    }
  },

  mounted() {
    this.getFiche()
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    getFiche() {
      this.emitter.emit('open-loader')

      this.fetchService
        .get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/prelevement`)
        .then((response) => {
          const data = response.data[0]
          this.fiche = data

          this.schema = this.formService.populateShema(data)

          if (data.organe) {
            this.schema.organe_id = {
              key: data.organe.id,
              value: data.organe.valeur,
            }
          }

          this.formService.setFormValues(this.schema)

          this.emitter.emit('close-loader')
        })
    },

    handleSubmit(values) {
      // eslint-disable-next-line no-param-reassign
      values.organe_id = values?.organe_id?.key ?? null

      this.fetchService
        .put(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/prelevement/${this.fiche.id}`, values)
        .then((response) => {
          this.fiche = response.data
          this.taskService.handleExperimentalTaskRedirect(this.$route)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
